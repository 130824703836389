import { Collapse, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import Column from '../Column'
import { gintoHeaderFontFamily } from '../../shared-components/typography'
import React, { useState } from 'react'
import IndexFaqItem from './IndexFaqItem'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { ChevronRight } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { indexMobileBreakpoint } from 'types/types'

interface Props {
  sx?: SxProps<Theme> | undefined
}

const IndexFAQ = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  const [showMore, setShowMore] = useState(false)

  return (
    <Column
      sx={[
        (theme) => ({
          gap: 7,
          alignItems: 'center',
          py: 9,
          [theme.breakpoints.down(indexMobileBreakpoint)]: {
            alignItems: 'flex-start',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        sx={(theme) => ({
          fontFamily: gintoHeaderFontFamily,
          fontSize: '2rem',
          fontWeight: 800,
          lineHeight: '1.8rem',
          letterSpacing: '-0.02em',
          [theme.breakpoints.down(indexMobileBreakpoint)]: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
          },
        })}
        variant='h3'
      >
        FAQ
      </Typography>

      <Column
        sx={{
          gap: 2,
        }}
      >
        {[...Array(5)].map((item, index) => (
          <IndexFaqItem
            key={index}
            question={t(`faq.item${index + 1}.question`)}
            answer={t(`faq.item${index + 1}.answer`)}
            listItems={t(`faq.item${index + 1}.listItems`)}
          />
        ))}

        <Collapse in={showMore}>
          <Column
            sx={{
              gap: 2,
            }}
          >
            {[...Array(12 - 5)].map((item, index) => (
              <IndexFaqItem
                key={index}
                question={t(`faq.item${index + 6}.question`)}
                answer={t(`faq.item${index + 6}.answer`)}
                listItems={t(`faq.item${index + 6}.listItems`)}
              />
            ))}
          </Column>
        </Collapse>

        <BashButton
          sx={{
            alignSelf: 'center',
          }}
          type={BashButtonType.CLEAR_SECONDARY}
          extraView={
            <ChevronRight
              sx={{
                transform: showMore ? 'rotate(-90deg)' : 'rotate(90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          }
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_END}
          onClick={() => setShowMore((prev) => !prev)}
        >
          {t(showMore ? 'showLess' : 'showMore')}
        </BashButton>
      </Column>
    </Column>
  )
}

export default IndexFAQ
